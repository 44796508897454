import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IGridContent } from '@impact/data';

@Component({
    selector: 'app-umb-grid',
    template: `
        <app-umb-section
            *ngFor="let section of grid?.sections"
            class="layout-grid"
            [section]="section"
        ></app-umb-section>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UmbGridComponent {
    @Input()
    grid?: IGridContent;
}
