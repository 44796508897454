import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { FormModule } from '../form/form.module';
import { UtilsModule } from '../utils/utils.module';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { InputComponent } from './input/input.component';
import { InputMultiRangeComponent } from './multi-range/multi-range.component';
import { RadioGroupComponent } from './radio-button/radio-button.component';
import { InputRangeComponent } from './range/range.component';
import { SelectComponent } from './select/select.component';
import { RadioToggleGroupComponent } from './radio-toggle/radio-toggle.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        UtilsModule,
        FormModule,
        TranslateModule.forChild(),
        NgbDatepickerModule
    ],
    declarations: [
        CheckboxComponent,
        DatepickerComponent,
        InputComponent,
        InputMultiRangeComponent,
        InputRangeComponent,
        RadioGroupComponent,
        RadioToggleGroupComponent,
        SelectComponent,
    ],
    exports: [
        CheckboxComponent,
        DatepickerComponent,
        InputComponent,
        InputMultiRangeComponent,
        InputRangeComponent,
        RadioGroupComponent,
        RadioToggleGroupComponent,
        SelectComponent,
    ],
})
export class FormElementsModule {}
