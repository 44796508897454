/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface BNApiGatewayApiCheckoutCalculateTotalOrderPriceResponse {
    /** @format double */
    carTotalPrice?: number;

    /** @format double */
    equipmentTotalPrice?: number;
}

export interface BNApiGatewayApiCheckoutCreatePurchaseOrderRequest {
    vin?: string | null;
}

export interface BNApiGatewayApiCheckoutGetPurchaseOrderResponse {
    /** @format uuid */
    purchaseOrderId?: string;

    /** @format date-time */
    creationDate?: string;
    vin?: string | null;
    createdFromOffer?: boolean;
    owner?: BNPurchaseOrderApiOwnerDto;
    coOwner?: BNPurchaseOrderApiOwnerDto;
    offer?: BNPurchaseOrderApiOfferDto;
    exchangeCar?: BNPurchaseOrderApiExchangeCar;

    /** @format uuid */
    consentRevisionAcceptedGuid?: string | null;
    delivery?: BNPurchaseOrderApiDeliveryDto;
    pickup?: BNPurchaseOrderApiPickupDto;
    insurance?: BNPurchaseOrderApiInsuranceDto;
    cash?: BNPurchaseOrderApiCashDto;
    loan?: BNPurchaseOrderApiSimpleLoanDto;
    lease?: BNPurchaseOrderApiSimpleLeaseDto;
    dealer?: BNPurchaseOrderApiDealerDto;
    product?: BNPurchaseOrderApiProductDto;
    brandName?: BNPurchaseOrderApiBrandName;
    equipment?: BNPurchaseOrderApiEquipmentDto[] | null;
    readyForCompletion?: boolean;
}

export interface BNApiGatewayApiLeasingGetCarListRequest {
    make?: string | null;
    model?: string | null;
    modelDescription?: string | null;
    operationalLeasing?: BNLouconApiOperationalLeasingDto;
}

export interface BNApiGatewayApiLoanLoanRequest {
    /** @format double */
    objectPrice?: number;

    /** @format double */
    downPayment?: number;

    /** @format int32 */
    paymentTerms?: number;

    /** @format date-time */
    registrationDate?: string | null;
    isFixedInterest?: boolean;
}

export interface BNApiGatewayApiLoanLoanResponse {
    /** @format double */
    totalLoanCosts?: number | null;

    /** @format double */
    baseAmount?: number | null;
    hasError?: boolean;
    errorMessage?: string | null;
    defaultDutyText?: string | null;

    /** @format double */
    totalRepay?: number | null;
    interestType?: string | null;

    /** @format double */
    aopBeforeTax?: number | null;

    /** @format double */
    aopBeforeTaxRaw?: number | null;

    /** @format double */
    financedAmount?: number | null;

    /** @format double */
    paymentPerMonth?: number;

    /** @format int32 */
    paymentTerms?: number;

    /** @format double */
    downPaymentPct?: number;

    /** @format double */
    downPayment?: number;

    /** @format double */
    objectPrice?: number;

    /** @format double */
    nominalInterest?: number | null;

    /** @format double */
    interestRate?: number | null;

    /** @format uuid */
    correlationId?: string;

    /** @format int32 */
    agreementId?: number | null;

    /** @format int32 */
    mileage?: number | null;

    /** @format int32 */
    modelYear?: number | null;
}

export interface BNApiGatewayApiOfferErrorResponse {
    errorMessage?: string | null;
}

export interface BNApiGatewayApiWorkshopCar {
    registrationNumber?: string | null;
    vin?: string | null;
    engine?: string | null;
    make?: string | null;
    makeCode?: string | null;
    model?: string | null;
    modelCode?: string | null;
    variant?: string | null;
    variantCode?: string | null;

    /** @format date-time */
    registrationDate?: string | null;
}

export enum BNCarSearchApiBrandName {
    Unknown = 'Unknown',
    BN = 'BN',
    BilernesHus = 'BilernesHus',
}

export interface BNCarSearchApiFilterCarsResponseDto {
    /** @format int64 */
    total?: number;
    facets?: Record<string, BNCarSearchApiViewModelFacetViewModel>;
    hits?: BNCarSearchApiViewModelCarShortViewModel[] | null;
}

export interface BNCarSearchApiGetCarDetailByIdResponseDto {
    car?: BNCarSearchApiViewModelCarViewModel;
}

export interface BNCarSearchApiViewModelCarShortViewModel {
    /** @format int32 */
    id?: number;
    make?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    model?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    variant?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    year?: BNCarSearchApiViewModelCarViewModelField1SystemInt16SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    mileage?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    motor?: BNCarSearchApiViewModelCarViewModelField1SystemNullable1SystemDecimalSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798ESystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    gearType?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    price?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    cashPrice?: BNCarSearchApiViewModelCarViewModelField1SystemNullable1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798ESystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    dealerAlternativeName?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    indicataPriceLevel?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    santanderPaymentPerMonth?: BNCarSearchApiViewModelCarViewModelField1SystemNullable1SystemDecimalSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798ESystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    createdDate?: BNCarSearchApiViewModelCarViewModelField1SystemDateTimeSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    isNew?: BNCarSearchApiViewModelCarViewModelField1SystemBooleanSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    isVan?: BNCarSearchApiViewModelCarViewModelField1SystemBooleanSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    isWholesale?: BNCarSearchApiViewModelCarViewModelField1SystemBooleanSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    isBuyable?: BNCarSearchApiViewModelCarViewModelField1SystemBooleanSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    priceIncludesVat?: BNCarSearchApiViewModelCarViewModelField1SystemBooleanSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    pictures?: string[] | null;

    /** @format uuid */
    vehicleId?: string;
    leasingPricePrivateOperational?: BNCarSearchApiViewModelCarViewModelField1SystemDecimalSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    leasingPricePrivateFinancial?: BNCarSearchApiViewModelCarViewModelField1SystemDecimalSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    leasingPriceBusinessOperational?: BNCarSearchApiViewModelCarViewModelField1SystemDecimalSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    leasingPriceBusinessFinancial?: BNCarSearchApiViewModelCarViewModelField1SystemDecimalSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    leasingDownpaymentPrivateOperational?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    leasingDownpaymentPrivateFinancial?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    leasingDownpaymentBusinessOperational?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    leasingDownpaymentBusinessFinancial?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    hasLeasingPricePrivateOperational?: BNCarSearchApiViewModelCarViewModelField1SystemBooleanSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    hasLeasingPricePrivateFinancial?: BNCarSearchApiViewModelCarViewModelField1SystemBooleanSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    hasLeasingPriceBusinessOperational?: BNCarSearchApiViewModelCarViewModelField1SystemBooleanSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    hasLeasingPriceBusinessFinancial?: BNCarSearchApiViewModelCarViewModelField1SystemBooleanSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    leasingDefaultDownPayment?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    leasingDefaultDurationInMonths?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    leasingDefaultKm?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
}

export interface BNCarSearchApiViewModelCarViewModel {
    /** @format int32 */
    id?: number;
    acceleration0To100?: BNCarSearchApiViewModelCarViewModelField1SystemNullable1SystemDecimalSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798ESystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    approvedFuelEconomy?: BNCarSearchApiViewModelCarViewModelField1SystemNullable1SystemDecimalSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798ESystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    batterySize?: BNCarSearchApiViewModelCarViewModelField1SystemNullable1SystemDecimalSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798ESystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    bodyTypes?: BNCarSearchApiViewModelCarViewModelField1SystemCollectionsGenericList1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798ESystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    cashPrice?: BNCarSearchApiViewModelCarViewModelField1SystemNullable1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798ESystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    color?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    comment?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    createdDate?: BNCarSearchApiViewModelCarViewModelField1SystemDateTimeSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    cylinders?: BNCarSearchApiViewModelCarViewModelField1SystemSByteSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    dealer?: BNCarSearchApiViewModelCarViewModelField1BNCarSearchApiViewModelDealerViewModelBNCarSearchApiVersion1000CultureNeutralPublicKeyTokenNull;
    driveWheels?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    effect?: BNCarSearchApiViewModelCarViewModelField1SystemInt16SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    effectInNm?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    electricConsumption?: BNCarSearchApiViewModelCarViewModelField1SystemNullable1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798ESystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    gasTankMax?: BNCarSearchApiViewModelCarViewModelField1SystemSByteSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    gearType?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    greenTax?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    height?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    indexDate?: BNCarSearchApiViewModelCarViewModelField1SystemDateTimeSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    inspectionDate?: BNCarSearchApiViewModelCarViewModelField1SystemNullable1SystemDateTimeSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798ESystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    isVan?: BNCarSearchApiViewModelCarViewModelField1SystemBooleanSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    isWholesale?: BNCarSearchApiViewModelCarViewModelField1SystemBooleanSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    isBuyable?: BNCarSearchApiViewModelCarViewModelField1SystemBooleanSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    priceIncludesVat?: BNCarSearchApiViewModelCarViewModelField1SystemBooleanSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    isNew?: BNCarSearchApiViewModelCarViewModelField1SystemBooleanSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    kmPerLiter?: BNCarSearchApiViewModelCarViewModelField1SystemDecimalSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    length?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    make?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    mileage?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    model?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    modifiedDate?: BNCarSearchApiViewModelCarViewModelField1SystemDateTimeSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    motor?: BNCarSearchApiViewModelCarViewModelField1SystemNullable1SystemDecimalSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798ESystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    newPrice?: BNCarSearchApiViewModelCarViewModelField1SystemNullable1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798ESystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    numberOfAirbags?: BNCarSearchApiViewModelCarViewModelField1SystemSByteSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    numberOfDoors?: BNCarSearchApiViewModelCarViewModelField1SystemSByteSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    numberOfGears?: BNCarSearchApiViewModelCarViewModelField1SystemSByteSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    payload?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    price?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    productionYear?: BNCarSearchApiViewModelCarViewModelField1SystemInt16SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    propellant?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    range?: BNCarSearchApiViewModelCarViewModelField1SystemNullable1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798ESystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    registrationDate?: BNCarSearchApiViewModelCarViewModelField1SystemNullable1SystemDateTimeSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798ESystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    santanderDownPayment?: BNCarSearchApiViewModelCarViewModelField1SystemNullable1SystemDecimalSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798ESystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    santanderPaymentPerMonth?: BNCarSearchApiViewModelCarViewModelField1SystemNullable1SystemDecimalSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798ESystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    serviceBook?: BNCarSearchApiViewModelCarViewModelField1SystemBooleanSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    topSpeed?: BNCarSearchApiViewModelCarViewModelField1SystemInt16SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    totalWeight?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    trailerWeight?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    upholstery?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    upholsteryColor?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    valvesPerCylinder?: BNCarSearchApiViewModelCarViewModelField1SystemNullable1SystemByteSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798ESystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    variant?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    vin?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    weight?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    weightTax?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    width?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    year?: BNCarSearchApiViewModelCarViewModelField1SystemInt16SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    indicataPriceLevel?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    type?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    typeID?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    steelPrice?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    registrationFee?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    video?: string | null;
    equipmentList?: string[] | null;
    pictures?: string[] | null;
    specificationGroups?: Record<string, BNCarSearchApiViewModelFieldGroupingViewModel>;

    /** @format uuid */
    vehicleId?: string;
    leasingPricePrivateOperational?: BNCarSearchApiViewModelCarViewModelField1SystemDecimalSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    leasingPricePrivateFinancial?: BNCarSearchApiViewModelCarViewModelField1SystemDecimalSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    leasingPriceBusinessOperational?: BNCarSearchApiViewModelCarViewModelField1SystemDecimalSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    leasingPriceBusinessFinancial?: BNCarSearchApiViewModelCarViewModelField1SystemDecimalSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    leasingDownpaymentPrivateOperational?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    leasingDownpaymentPrivateFinancial?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    leasingDownpaymentBusinessOperational?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    leasingDownpaymentBusinessFinancial?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    hasLeasingPricePrivateOperational?: BNCarSearchApiViewModelCarViewModelField1SystemBooleanSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    hasLeasingPricePrivateFinancial?: BNCarSearchApiViewModelCarViewModelField1SystemBooleanSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    hasLeasingPriceBusinessOperational?: BNCarSearchApiViewModelCarViewModelField1SystemBooleanSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    hasLeasingPriceBusinessFinancial?: BNCarSearchApiViewModelCarViewModelField1SystemBooleanSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    leasingDefaultDownPayment?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    leasingDefaultDurationInMonths?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    leasingDefaultKm?: BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
}

export interface BNCarSearchApiViewModelCarViewModelField1BNCarSearchApiViewModelDealerViewModelBNCarSearchApiVersion1000CultureNeutralPublicKeyTokenNull {
    data?: BNCarSearchApiViewModelDealerViewModel;
    label?: string | null;
    value?: string | null;
}

export interface BNCarSearchApiViewModelCarViewModelField1SystemBooleanSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E {
    data?: boolean;
    label?: string | null;
    value?: string | null;
}

export interface BNCarSearchApiViewModelCarViewModelField1SystemCollectionsGenericList1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798ESystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E {
    data?: string[] | null;
    label?: string | null;
    value?: string | null;
}

export interface BNCarSearchApiViewModelCarViewModelField1SystemDateTimeSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E {
    /** @format date-time */
    data?: string;
    label?: string | null;
    value?: string | null;
}

export interface BNCarSearchApiViewModelCarViewModelField1SystemDecimalSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E {
    /** @format double */
    data?: number;
    label?: string | null;
    value?: string | null;
}

export interface BNCarSearchApiViewModelCarViewModelField1SystemInt16SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E {
    /** @format int32 */
    data?: number;
    label?: string | null;
    value?: string | null;
}

export interface BNCarSearchApiViewModelCarViewModelField1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E {
    /** @format int32 */
    data?: number;
    label?: string | null;
    value?: string | null;
}

export interface BNCarSearchApiViewModelCarViewModelField1SystemNullable1SystemByteSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798ESystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E {
    /** @format int32 */
    data?: number | null;
    label?: string | null;
    value?: string | null;
}

export interface BNCarSearchApiViewModelCarViewModelField1SystemNullable1SystemDateTimeSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798ESystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E {
    /** @format date-time */
    data?: string | null;
    label?: string | null;
    value?: string | null;
}

export interface BNCarSearchApiViewModelCarViewModelField1SystemNullable1SystemDecimalSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798ESystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E {
    /** @format double */
    data?: number | null;
    label?: string | null;
    value?: string | null;
}

export interface BNCarSearchApiViewModelCarViewModelField1SystemNullable1SystemInt32SystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798ESystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E {
    /** @format int32 */
    data?: number | null;
    label?: string | null;
    value?: string | null;
}

export interface BNCarSearchApiViewModelCarViewModelField1SystemSByteSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E {
    /** @format int32 */
    data?: number;
    label?: string | null;
    value?: string | null;
}

export interface BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E {
    data?: string | null;
    label?: string | null;
    value?: string | null;
}

export interface BNCarSearchApiViewModelDealerViewModel {
    dealerAddressCity?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    dealerAddressStreetLine1?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    dealerAddressStreetLine2?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    dealerAddressZipCode?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    dealerAlternativeName?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    dealerAlternativePhoneNumber?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    dealerEmail?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;

    /** @format uuid */
    dealerGuid?: string;

    /** @format int32 */
    dealerId?: number;
    dealerName?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    dealerPhoneNumber?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    dealerWebsite?: BNCarSearchApiViewModelCarViewModelField1SystemStringSystemPrivateCoreLibVersion6000CultureNeutralPublicKeyToken7Cec85D7Bea7798E;
    dealerBrand?: BNCarSearchApiBrandName;
}

export interface BNCarSearchApiViewModelFacetItemViewModel {
    key?: string | null;
    label?: string | null;

    /** @format int64 */
    count?: number;
    selected?: boolean;
}

export interface BNCarSearchApiViewModelFacetViewModel {
    label?: string | null;
    items?: BNCarSearchApiViewModelFacetItemViewModel[] | null;
}

export interface BNCarSearchApiViewModelFieldGroupingViewModel {
    fields?: BNCarSearchApiViewModelICarViewModelField[] | null;
    label?: string | null;
}

export interface BNCarSearchApiViewModelICarViewModelField {
    data?: any;
    label?: string | null;
    value?: string | null;
}

export interface BNConsentApiGetPermissionResponseDto {
    permissionText?: string | null;

    /** @format uuid */
    revisionGuid?: string;
}

export enum BNDracarApiBrandName {
    BN = 'BN',
    BilernesHus = 'BilernesHus',
}

export interface BNDracarApiCarClassDto {
    /** @format int32 */
    carClassId?: number;
    name?: string | null;
}

export interface BNDracarApiCarPickupDto {
    address?: string | null;
    zipCode?: string | null;

    /** @format date-time */
    time?: string;
}

export interface BNDracarApiMakeDto {
    code?: string | null;
    name?: string | null;
}

export interface BNDracarApiModelDto {
    code?: string | null;
    name?: string | null;
}

export interface BNDracarApiOptionDto {
    id?: string | null;
    name?: string | null;
    description?: string | null;
    default?: boolean;

    /** @format double */
    price?: number;
    requiresAddress?: boolean;
}

export interface BNDracarApiPersonalInfoDto {
    firstName?: string | null;
    lastName?: string | null;
    address?: string | null;
    zipCode?: string | null;
    city?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
}

export interface BNDracarApiRequestsAddCustomerVehicleRequestDto {
    brandName?: BNDracarApiBrandName;
    email?: string | null;
    makeCode?: string | null;
    modelCode?: string | null;
    variantCode?: string | null;
    registrationNumber?: string | null;

    /** @format date-time */
    registrationDate?: string | null;
}

export interface BNDracarApiRequestsCreateBookingRequestDto {
    brandName?: BNDracarApiBrandName;
    shopCode?: string | null;
    makeCode?: string | null;
    modelCode?: string | null;
    modelDescription?: string | null;
    variantCode?: string | null;

    /** @format date-time */
    registrationDate?: string;
    registrationNumber?: string | null;
    chassisNumber?: string | null;
    optionId?: string | null;

    /** @format date-time */
    date?: string;
    timeCode?: string | null;
    personalInfo?: BNDracarApiPersonalInfoDto;
    pickupData?: BNDracarApiCarPickupDto;
    rentalCar?: boolean;
    jobCodes?: string[] | null;
    comment?: string | null;

    /** @format int32 */
    mileage?: number;
    rentalCarClassName?: string | null;

    /** @format int32 */
    rentalBranchOfficeId?: number;
}

export interface BNDracarApiRequestsRegisterCustomerRequestDto {
    brandName?: BNDracarApiBrandName;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    address?: string | null;
    zipCode?: string | null;
    phoneNumber?: string | null;
}

export interface BNDracarApiResponsesAddCustomerVehicleResponseDto {
    /** @format int32 */
    vehicleId?: number;
}

export type BNDracarApiResponsesRegisterCustomerResponseDto = object;

export type BNDracarApiResponsesUpdateCompletedDto = object;

export enum BNDracarApiResponsesValidationErrorCode {
    IncompleteRequest = 'IncompleteRequest',
    IsEmpty = 'IsEmpty',
    InvalidFormat = 'InvalidFormat',
    InvalidValue = 'InvalidValue',
}

export interface BNDracarApiResponsesValidationErrorDto {
    property?: string | null;
    errorCode?: BNDracarApiResponsesValidationErrorCode;
}

export interface BNDracarApiServiceDto {
    code?: string | null;
    title?: string | null;
    description?: string | null;

    /** @format double */
    durationInHours?: number;

    /** @format double */
    price?: number;
}

export interface BNDracarApiShopDto {
    id?: string | null;
    dealershipName?: string | null;
    address1?: string | null;
    address2?: string | null;
    address3?: string | null;
    fullAddress?: string | null;
    postalCode?: string | null;
    country?: string | null;
    telephone?: string | null;
    fax?: string | null;

    /** @format double */
    latitude?: number;

    /** @format double */
    longitude?: number;
    emailAddress?: string | null;
}

export interface BNDracarApiVariantDto {
    code?: string | null;
    name?: string | null;
}

export interface BNLeasingPurchaseOrderApiLeasingAddressDto {
    address?: string | null;
    zipCode?: string | null;
    city?: string | null;
}

export interface BNLeasingPurchaseOrderApiLeasingColorsDto {
    /** @format int32 */
    interiorOptionId?: number;
    interiorDescription?: string | null;

    /** @format double */
    interiorPrice?: number;

    /** @format int32 */
    exteriorOptionId?: number;
    exteriorDescription?: string | null;

    /** @format double */
    exteriorPrice?: number;
}

export interface BNLeasingPurchaseOrderApiLeasingDataDto {
    /** @format int32 */
    duration?: number;

    /** @format int32 */
    kmPerYear?: number;

    /** @format double */
    downPayment?: number;
    leasingType?: string | null;

    /** @format double */
    monthlyPayment?: number;

    /** @format double */
    residualValue?: number;

    /** @format double */
    totalPrice?: number;

    /** @format double */
    insurancePrMonth?: number;

    /** @format double */
    taxBase?: number;

    /** @format double */
    taxPerMonth?: number;
    includeInsurance?: boolean;
}

export interface BNLeasingPurchaseOrderApiLeasingDeliveryDto {
    address?: string | null;
    zip?: string | null;
    city?: string | null;

    /** @format date-time */
    deliveryDate?: string;
}

export enum BNLeasingPurchaseOrderApiLeasingDeliveryTypes {
    Pickup = 'Pickup',
    Delivery = 'Delivery',
}

export interface BNLeasingPurchaseOrderApiLeasingExtraOptionDto {
    /** @format int32 */
    optionId?: number;
    optionDescription?: string | null;

    /** @format double */
    optionPrice?: number;
}

export interface BNLeasingPurchaseOrderApiLeasingOptionDto {
    /** @format int32 */
    optionId?: number;
    optionDescription?: string | null;

    /** @format double */
    optionPrice?: number;
    optionType?: string | null;
}

export interface BNLeasingPurchaseOrderApiLeasingPickupDto {
    /** @format date-time */
    pickupDate?: string;
}

export interface BNLeasingPurchaseOrderApiLeasingPrimaryUserDto {
    cvr?: string | null;
    companyName?: string | null;
    companyEmail?: string | null;
    companyPhone?: string | null;
    address?: BNLeasingPurchaseOrderApiLeasingAddressDto;
    user?: BNLeasingPurchaseOrderApiLeasingUserDto;
}

export interface BNLeasingPurchaseOrderApiLeasingUserDto {
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    phone?: string | null;
}

export interface BNLeasingPurchaseOrderApiRequestsCompleteLeasingPurchaseOrderRequestDto {
    /** @format uuid */
    purchaseOrderId?: string;
}

export interface BNLeasingPurchaseOrderApiRequestsCreateLeasingPurchaseOrderRequestDto {
    vehicleId?: string | null;

    /** @format int32 */
    duration?: number;

    /** @format int32 */
    kmPerYear?: number;

    /** @format double */
    downPayment?: number;

    /** @format double */
    monthlyPayment?: number;
    leasingType?: string | null;
    make?: string | null;
    model?: string | null;
    variant?: string | null;
    siteName?: string | null;
}

export interface BNLeasingPurchaseOrderApiRequestsUpdateLeasingDataRequestDto {
    /** @format uuid */
    purchaseOrderId?: string;

    /** @format int32 */
    duration?: number;

    /** @format int32 */
    kmPerYear?: number;

    /** @format double */
    downPayment?: number;
    leasingType?: string | null;

    /** @format double */
    monthlyPayment?: number;

    /** @format double */
    residualValue?: number;

    /** @format double */
    totalPrice?: number;

    /** @format double */
    insurancePrMonth?: number;

    /** @format double */
    taxBase?: number;

    /** @format double */
    taxPerMonth?: number;
}

export interface BNLeasingPurchaseOrderApiRequestsUpdateLeasingExtraOptionsRequestDto {
    /** @format uuid */
    purchaseOrderId?: string;
    includeInsurance?: boolean;
    options?: BNLeasingPurchaseOrderApiLeasingExtraOptionDto[] | null;
}

export interface BNLeasingPurchaseOrderApiRequestsUpdateLeasingOptionsRequestDto {
    /** @format uuid */
    purchaseOrderId?: string;
    colors?: BNLeasingPurchaseOrderApiLeasingColorsDto;
    options?: BNLeasingPurchaseOrderApiLeasingOptionDto[] | null;
}

export interface BNLeasingPurchaseOrderApiRequestsUpdateLeasingPickupDeliveryRequestDto {
    /** @format uuid */
    purchaseOrderId?: string;
    deliveryType?: BNLeasingPurchaseOrderApiLeasingDeliveryTypes;

    /** @format date-time */
    dateTime?: string;
    delivery?: BNLeasingPurchaseOrderApiLeasingDeliveryDto;
}

export interface BNLeasingPurchaseOrderApiRequestsUpdateLeasingStepInfoDto {
    /** @format uuid */
    purchaseOrderId?: string;
    stepInfo?: string | null;
}

export interface BNLeasingPurchaseOrderApiRequestsUpdateLeasingUserInfoRequestDto {
    /** @format uuid */
    purchaseOrderId?: string;
    primaryUser?: BNLeasingPurchaseOrderApiLeasingPrimaryUserDto;
    secondaryUser?: BNLeasingPurchaseOrderApiLeasingUserDto;
}

export interface BNLeasingPurchaseOrderApiResponsesCreateLeasingPurchaseOrderCompletedDto {
    /** @format uuid */
    purchaseOrderId?: string;
}

export interface BNLeasingPurchaseOrderApiResponsesCreateLeasingPurchaseOrderUpdateResponseDto {
    result?: BNMonadsEither2BNLeasingPurchaseOrderApiResponsesCreateLeasingPurchaseOrderCompletedDtoBNLeasingPurchaseOrderApiVersion1000CultureNeutralPublicKeyTokenNullBNLeasingPurchaseOrderApiResponsesValidationFailedDtoBNLeasingPurchaseOrderApiVersion1000CultureNeutralPublicKeyTokenNull;
}

export interface BNLeasingPurchaseOrderApiResponsesLeasingPurchaseOrderResponseDto {
    /** @format uuid */
    purchaseOrderId?: string;

    /** @format date-time */
    creationDate?: string;

    /** @format date-time */
    completedDate?: string | null;
    name?: string | null;
    make?: string | null;
    model?: string | null;
    variant?: string | null;
    vehicleId?: string | null;
    checkoutStep?: string | null;
    colors?: BNLeasingPurchaseOrderApiLeasingColorsDto;
    options?: BNLeasingPurchaseOrderApiLeasingOptionDto[] | null;
    extraOptions?: BNLeasingPurchaseOrderApiLeasingExtraOptionDto[] | null;
    primaryUser?: BNLeasingPurchaseOrderApiLeasingPrimaryUserDto;
    secondaryUser?: BNLeasingPurchaseOrderApiLeasingUserDto;
    delivery?: BNMonadsMaybe1BNMonadsEither2BNLeasingPurchaseOrderApiLeasingDeliveryDtoBNLeasingPurchaseOrderApiVersion1000CultureNeutralPublicKeyTokenNullBNLeasingPurchaseOrderApiLeasingPickupDtoBNLeasingPurchaseOrderApiVersion1000CultureNeutralPublicKeyTokenNullBNMonadsVersion1000CultureNeutralPublicKeyTokenNull;
    leasingData?: BNLeasingPurchaseOrderApiLeasingDataDto;
    completed?: boolean;
    readyForCompletion?: boolean;
    siteName?: string | null;
}

export interface BNLeasingPurchaseOrderApiResponsesLeasingUpdateCompletedDto {
    /** @format uuid */
    purchaseOrderId?: string;
}

export enum BNLeasingPurchaseOrderApiResponsesValidationErrorCode {
    IncompleteRequest = 'IncompleteRequest',
    IsEmpty = 'IsEmpty',
    InvalidFormat = 'InvalidFormat',
    InvalidValue = 'InvalidValue',
    NotBuyable = 'NotBuyable',
    DataFetchError = 'DataFetchError',
}

export interface BNLeasingPurchaseOrderApiResponsesValidationErrorDto {
    property?: string | null;
    errorMessage?: string | null;
    errorCode?: BNLeasingPurchaseOrderApiResponsesValidationErrorCode;
}

export interface BNLeasingPurchaseOrderApiResponsesValidationFailedDto {
    /** @format uuid */
    purchaseOrderId?: string;
    errors?: BNLeasingPurchaseOrderApiResponsesValidationErrorDto[] | null;
}

export interface BNLouconApiCarDetailsDto {
    fuelEconomy?: string | null;
    carbonDioxide?: string | null;
    frame?: string | null;
    horsepower?: string | null;
    gearbox?: string | null;
    fuelType?: string | null;
    environmentalCategory?: string | null;
}

export interface BNLouconApiCarDto {
    carInfo?: BNLouconApiCarInfoDto;
    carPrice?: BNLouconApiCarPriceDto;
    details?: BNLouconApiCarDetailsDto;
}

export interface BNLouconApiCarInfoDto {
    vehicleId?: string | null;
    make?: string | null;
    model?: string | null;
    edition?: string | null;
    trim?: string | null;
    vehicleType?: string | null;
}

export interface BNLouconApiCarPriceDto {
    /** @format double */
    pricePerMonth?: number;
    pricePerMonthFormatted?: string | null;

    /** @format double */
    taxPerMonth?: number;
    taxPerMonthFormatted?: string | null;

    /** @format double */
    taxBase?: number;
    taxBaseFormatted?: string | null;

    /** @format double */
    downPayment?: number;
    downPaymentFormatted?: string | null;
}

export interface BNLouconApiColorDescriptionDto {
    /** @format int32 */
    optionId?: number;
    optionType?: BNLouconApiOptionType;
    description?: string | null;
    colorCode?: string | null;
    default?: boolean;
}

export interface BNLouconApiExtraOptionDescriptionDto {
    /** @format int32 */
    optionId?: number;
    equipmentContent?: string | null;
    description?: string | null;
    price?: BNLouconApiOptionPriceDto;
}

export interface BNLouconApiGetCarColorDescriptionsRequestDto {
    vehicleId?: string | null;
    vehicleType?: string | null;

    /** @format int32 */
    durationInMonths?: number;
}

export interface BNLouconApiGetCarColorDescriptionsResponseDto {
    optionDescriptions?: BNLouconApiColorDescriptionDto[] | null;
}

export interface BNLouconApiGetCarExtraOptionsRequestDto {
    vehicleType?: string | null;
    make?: string | null;
    model?: string | null;
    modelDescription?: string | null;

    /** @format int32 */
    durationInMonths?: number;
}

export interface BNLouconApiGetCarExtraOptionsResponseDto {
    extraOptionDescriptions?: BNLouconApiExtraOptionDescriptionDto[] | null;
}

export interface BNLouconApiGetCarListResponseDto {
    cars?: BNLouconApiCarDto[] | null;
}

export interface BNLouconApiGetCarOptionDescriptionsRequestDto {
    vehicleId?: string | null;
    vehicleType?: string | null;

    /** @format int32 */
    durationInMonths?: number;
}

export interface BNLouconApiGetCarOptionDescriptionsResponseDto {
    optionDescriptions?: BNLouconApiOptionDescriptionDto[] | null;
}

export interface BNLouconApiGetCarOptionsRequestDto {
    vehicleId?: string | null;
    vehicleType?: string | null;

    /** @format int32 */
    durationInMonths?: number;
    selectedOptionIds?: number[] | null;
}

export interface BNLouconApiGetCarOptionsResponseDto {
    options?: BNLouconApiOptionDto[] | null;
}

export interface BNLouconApiGetCarPriceRequestDto {
    vehicleId?: string | null;
    optionIds?: number[] | null;
    extraOptionIds?: number[] | null;
    includeInsurance?: boolean;

    /** @format int32 */
    durationInMonths?: number;

    /** @format int32 */
    km?: number;

    /** @format int32 */
    downPayment?: number;

    /** @format int32 */
    scrapValue?: number;
    leasingType?: string | null;
}

export interface BNLouconApiGetCarPriceResponseDto {
    /** @format double */
    pricePerMonth?: number;
    pricePerMonthFormatted?: string | null;

    /** @format double */
    taxPerMonth?: number;
    taxPerMonthFormatted?: string | null;

    /** @format double */
    taxBase?: number;
    taxBaseFormatted?: string | null;

    /** @format double */
    tax?: number;
    taxFormatted?: string | null;

    /** @format double */
    totalPrice?: number;
    totalPriceFormatted?: string | null;

    /** @format double */
    insurancePerMonth?: number;
    insurancePerMonthFormatted?: string | null;
}

export interface BNLouconApiGetUsedCarExtraOptionsRequestDto {
    leasingtypeForPriceCalculation?: string | null;
    vehicleType?: string | null;
    make?: string | null;
    model?: string | null;
    modelDescription?: string | null;

    /** @format int32 */
    durationInMonths?: number;
}

export interface BNLouconApiGetUsedCarIntegratedValuesRequestDto {
    /** @format int32 */
    carMilage?: number;

    /** @format int32 */
    downpayment?: number | null;

    /** @format int32 */
    durationInMonths?: number;
    extraOptions?: number[] | null;

    /** @format date-time */
    firstRegistrationDate?: string;
    fueltype?: string | null;

    /** @format int32 */
    km?: number;
    isActiveMotorPoint?: boolean;
    isLeasingForBusiness?: boolean;
    isLeasingTypeFinancial?: boolean;
    make?: string | null;
    model?: string | null;
    modelDescription?: string | null;

    /** @format int32 */
    registrationFee?: number;

    /** @format int32 */
    steelPrice?: number;
    vehicleType?: string | null;
}

export interface BNLouconApiGetUsedCarIntegratedValuesResponseDto {
    /** @format int32 */
    downpaymentMin?: number;

    /** @format int32 */
    downpaymentMax?: number;

    /** @format int32 */
    kmPerYearMin?: number;

    /** @format int32 */
    kmPerYearMax?: number;
    leasingDurationValues?: number[] | null;

    /** @format int32 */
    defaultDownpayment?: number;

    /** @format int32 */
    defaultDuration?: number;

    /** @format int32 */
    defaultKmPerYear?: number;

    /** @format double */
    pricePerMonth?: number;

    /** @format double */
    scrapValue?: number;
}

export interface BNLouconApiGetUsedCarPriceRequestDto {
    password?: string | null;
    isLeasingTypeFinancial?: boolean;
    isLeasingForBusiness?: boolean;
    isActiveMotorPoint?: boolean;
    vehicleType?: string | null;
    make?: string | null;
    model?: string | null;
    modelDescription?: string | null;

    /** @format int32 */
    durationInMonths?: number;

    /** @format double */
    steelPrice?: number;

    /** @format int32 */
    registrationFee?: number;

    /** @format date-time */
    firstRegistrationDate?: string;

    /** @format int32 */
    carMilage?: number;
    fueltype?: string | null;

    /** @format int32 */
    km?: number;

    /** @format int32 */
    downpayment?: number;

    /** @format int32 */
    scrapValue?: number;
    extraOptions?: number[] | null;
}

export interface BNLouconApiGetUsedCarPriceResponseDto {
    /** @format int32 */
    getUsedCarPriceResult?: number;

    /** @format double */
    pricePerMonth?: number;

    /** @format double */
    scrapValue?: number;
}

export interface BNLouconApiGetUsedCarValidIntervalsRequestDto {
    password?: string | null;
    isLeasingTypeFinancial?: boolean;
    isLeasingForBusiness?: boolean;
    vehicleType?: string | null;
    make?: string | null;
    model?: string | null;
    modelDescription?: string | null;

    /** @format int32 */
    steelPrice?: number;

    /** @format date-time */
    firstRegistrationDate?: string;

    /** @format int32 */
    carMilage?: number;
    fueltype?: string | null;
}

export interface BNLouconApiGetUsedCarValidIntervalsResponseDto {
    /** @format int32 */
    minDurationMonths?: number;

    /** @format int32 */
    maxDurationMonths?: number;

    /** @format int32 */
    minKm?: number;

    /** @format int32 */
    maxKm?: number;

    /** @format int32 */
    minDownPaymentPct?: number;

    /** @format int32 */
    maxDownPaymentPct?: number;
}

export interface BNLouconApiGetValidIntervalsRequestDto {
    make?: string | null;
    model?: string | null;
    modelDescription?: string | null;
}

export interface BNLouconApiGetValidIntervalsResponseDto {
    /** @format int32 */
    minDurationMonths?: number;

    /** @format int32 */
    maxDurationMonths?: number;

    /** @format int32 */
    minKm?: number;

    /** @format int32 */
    maxKm?: number;

    /** @format int32 */
    minDownPaymentPct?: number;

    /** @format int32 */
    maxDownPaymentPct?: number;
}

export interface BNLouconApiOperationalLeasingDto {
    /** @format int32 */
    durationInMonths?: number;

    /** @format int32 */
    km?: number;

    /** @format int32 */
    downPayment?: number;
}

export interface BNLouconApiOptionDescriptionDto {
    /** @format int32 */
    optionId?: number;
    optionType?: BNLouconApiOptionType;
    description?: string | null;
    equipmentContent?: string | null;
}

export interface BNLouconApiOptionDto {
    /** @format int32 */
    optionId?: number;
    enabled?: boolean;
    selected?: boolean;
    optionType?: BNLouconApiOptionType;
    price?: BNLouconApiOptionPriceDto;
    includedOptions?: number[] | null;
    requiredOptions?: number[][] | null;
    excludedOptions?: number[] | null;
    isPartOfOptions?: number[] | null;
}

export interface BNLouconApiOptionPriceDto {
    /** @format double */
    pricePerMonth?: number;
    pricePerMonthFormatted?: string | null;
}

export enum BNLouconApiOptionType {
    Pack = 'Pack',
    Option = 'Option',
    Interior = 'Interior',
    Exterior = 'Exterior',
}

export interface BNMonadsEither2BNLeasingPurchaseOrderApiLeasingDeliveryDtoBNLeasingPurchaseOrderApiVersion1000CultureNeutralPublicKeyTokenNullBNLeasingPurchaseOrderApiLeasingPickupDtoBNLeasingPurchaseOrderApiVersion1000CultureNeutralPublicKeyTokenNull {
    isLeft?: boolean;
    left?: BNLeasingPurchaseOrderApiLeasingDeliveryDto;
    right?: BNLeasingPurchaseOrderApiLeasingPickupDto;
}

export interface BNMonadsEither2BNLeasingPurchaseOrderApiResponsesCreateLeasingPurchaseOrderCompletedDtoBNLeasingPurchaseOrderApiVersion1000CultureNeutralPublicKeyTokenNullBNLeasingPurchaseOrderApiResponsesValidationFailedDtoBNLeasingPurchaseOrderApiVersion1000CultureNeutralPublicKeyTokenNull {
    isLeft?: boolean;
    left?: BNLeasingPurchaseOrderApiResponsesCreateLeasingPurchaseOrderCompletedDto;
    right?: BNLeasingPurchaseOrderApiResponsesValidationFailedDto;
}

export interface BNMonadsEither2BNPurchaseOrderApiResponsesCreatePurchaseOrderCompletedDtoBNPurchaseOrderApiVersion1000CultureNeutralPublicKeyTokenNullBNPurchaseOrderApiResponsesValidationFailedDtoBNPurchaseOrderApiVersion1000CultureNeutralPublicKeyTokenNull {
    isLeft?: boolean;
    left?: BNPurchaseOrderApiResponsesCreatePurchaseOrderCompletedDto;
    right?: BNPurchaseOrderApiResponsesValidationFailedDto;
}

export interface BNMonadsMaybe1BNMonadsEither2BNLeasingPurchaseOrderApiLeasingDeliveryDtoBNLeasingPurchaseOrderApiVersion1000CultureNeutralPublicKeyTokenNullBNLeasingPurchaseOrderApiLeasingPickupDtoBNLeasingPurchaseOrderApiVersion1000CultureNeutralPublicKeyTokenNullBNMonadsVersion1000CultureNeutralPublicKeyTokenNull {
    value?: BNMonadsEither2BNLeasingPurchaseOrderApiLeasingDeliveryDtoBNLeasingPurchaseOrderApiVersion1000CultureNeutralPublicKeyTokenNullBNLeasingPurchaseOrderApiLeasingPickupDtoBNLeasingPurchaseOrderApiVersion1000CultureNeutralPublicKeyTokenNull;
    isSome?: boolean;
}

export interface BNPaymentApiPaymentCreatedDto {
    paymentPageUrl?: string | null;
    paymentId?: string | null;
}

export interface BNPurchaseOrderApiAddressDto {
    address?: string | null;
    zipCode?: string | null;
    city?: string | null;
}

export enum BNPurchaseOrderApiBrandName {
    Unknown = 'Unknown',
    BN = 'BN',
    BilernesHus = 'BilernesHus',
    LeasingDK = 'LeasingDK',
}

export type BNPurchaseOrderApiCashDto = object;

export interface BNPurchaseOrderApiDealerDto {
    dealerName?: string | null;

    /** @format int32 */
    dealerId?: number;

    /** @format uuid */
    dealerGuid?: string;
    dealerAlternativeName?: string | null;
    dealerAddressStreetLine1?: string | null;
    dealerAddressStreetLine2?: string | null;
    dealerAddressZipCode?: string | null;
    dealerAddressCity?: string | null;
    dealerPhoneNumber?: string | null;
    dealerAlternativePhoneNumber?: string | null;
    dealerEmail?: string | null;
    dealerWebsite?: string | null;
}

export interface BNPurchaseOrderApiDeliveryDto {
    address?: BNPurchaseOrderApiAddressDto;

    /** @format date-time */
    dateTime?: string;
}

export enum BNPurchaseOrderApiDeliveryTypes {
    Pickup = 'Pickup',
    Delivery = 'Delivery',
}

export interface BNPurchaseOrderApiEquipmentDto {
    name?: string | null;

    /** @format double */
    priceExclWat?: number;

    /** @format double */
    wat?: number;
    watFree?: boolean;

    /** @format double */
    priceInclWat?: number;

    /** @format uuid */
    id?: string;
    enabled?: boolean;
    originalAftermarketEquipmentId?: string | null;
}

export interface BNPurchaseOrderApiExchangeCar {
    /** @format double */
    price?: number | null;
    make?: string | null;
    model?: string | null;
    variant?: string | null;
    indicataUrl?: string | null;

    /** @format int32 */
    year?: number | null;

    /** @format int32 */
    milage?: number | null;
}

export interface BNPurchaseOrderApiInsuranceDto {
    company?: string | null;
}

export interface BNPurchaseOrderApiLoanDto {
    /** @format double */
    aopBeforeTaxRaw?: number;

    /** @format double */
    objectPrice?: number;

    /** @format double */
    downPayment?: number;

    /** @format double */
    downPaymentPct?: number;

    /** @format int32 */
    paymentTerms?: number;

    /** @format double */
    paymentPerMonth?: number;

    /** @format double */
    financedAmount?: number;

    /** @format double */
    nominalInterest?: number;

    /** @format double */
    aopBeforeTax?: number;

    /** @format double */
    totalRepay?: number;
    errorMessage?: string | null;

    /** @format uuid */
    correlationId?: string;

    /** @format int32 */
    modelYear?: number;

    /** @format int32 */
    mileage?: number;

    /** @format date-time */
    firstRegistrationDate?: string | null;
    hasError?: boolean;

    /** @format double */
    baseAmount?: number;

    /** @format double */
    totalLoanCosts?: number;
    interestType?: string | null;

    /** @format int32 */
    agreementId?: number;

    /** @format double */
    interestRate?: number;
}

export interface BNPurchaseOrderApiOfferDto {
    financialSituationClearedOnOffer?: boolean;

    /** @format double */
    exchangePrice?: number;
    closedOnExchangePrice?: boolean;
}

export interface BNPurchaseOrderApiOwnerDto {
    cpr?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    phone?: string | null;
    address?: BNPurchaseOrderApiAddressDto;
}

export interface BNPurchaseOrderApiPickupDto {
    /** @format date-time */
    dateTime?: string;
}

export interface BNPurchaseOrderApiProductDto {
    /** @format int32 */
    productId?: number;
    vin?: string | null;

    /** @format double */
    netPrice?: number;

    /** @format double */
    grossPrice?: number;

    /** @format double */
    taxRate?: number;

    /** @format double */
    taxAmount?: number;
    name?: string | null;
    make?: string | null;
    model?: string | null;
    variant?: string | null;

    /** @format int32 */
    mileage?: number;

    /** @format int32 */
    year?: number;
}

export interface BNPurchaseOrderApiRequestsCompletePurchaseOrderRequestDto {
    /** @format uuid */
    purchaseOrderId?: string;
    returnUrl?: string | null;
    termsUrl?: string | null;
}

export interface BNPurchaseOrderApiRequestsEquipmentRequestDto {
    /** @format uuid */
    purchaseOrderId?: string;
    equipment?: BNPurchaseOrderApiEquipmentDto[] | null;
}

export interface BNPurchaseOrderApiRequestsExchangeCarRequestDto {
    /** @format uuid */
    purchaseOrderId?: string;
    exchangeCar?: BNPurchaseOrderApiExchangeCar;
    createdFromOffer?: boolean;
}

export interface BNPurchaseOrderApiRequestsUpdateCashRequestDto {
    /** @format uuid */
    purchaseOrderId?: string;
    cash?: BNPurchaseOrderApiCashDto;
}

export interface BNPurchaseOrderApiRequestsUpdateInsuranceRequestDto {
    /** @format uuid */
    purchaseOrderId?: string;
    insurance?: BNPurchaseOrderApiInsuranceDto;
    createdFromOffer?: boolean;
}

export interface BNPurchaseOrderApiRequestsUpdateLeasingRequestDto {
    /** @format uuid */
    purchaseOrderId?: string;
    lease?: BNPurchaseOrderApiSimpleLeaseDto;
}

export interface BNPurchaseOrderApiRequestsUpdateLoanRequestDto {
    /** @format uuid */
    purchaseOrderId?: string;
    loan?: BNPurchaseOrderApiLoanDto;
}

export interface BNPurchaseOrderApiRequestsUpdateOwnershipRequestDto {
    /** @format uuid */
    purchaseOrderId?: string;
    owner?: BNPurchaseOrderApiOwnerDto;
    coOwner?: BNPurchaseOrderApiOwnerDto;

    /** @format uuid */
    consentRevisionAcceptedGuid?: string | null;
    createdFromOffer?: boolean;
}

export interface BNPurchaseOrderApiRequestsUpdatePickupDeliveryRequestDto {
    /** @format uuid */
    purchaseOrderId?: string;
    deliveryType?: BNPurchaseOrderApiDeliveryTypes;
    pickup?: BNPurchaseOrderApiPickupDto;
    delivery?: BNPurchaseOrderApiDeliveryDto;
}

export interface BNPurchaseOrderApiResponsesCreatePurchaseOrderCompletedDto {
    /** @format uuid */
    purchaseOrderId?: string;
    createdFromOffer?: boolean;
}

export interface BNPurchaseOrderApiResponsesCreatePurchaseOrderUpdateResponseDto {
    result?: BNMonadsEither2BNPurchaseOrderApiResponsesCreatePurchaseOrderCompletedDtoBNPurchaseOrderApiVersion1000CultureNeutralPublicKeyTokenNullBNPurchaseOrderApiResponsesValidationFailedDtoBNPurchaseOrderApiVersion1000CultureNeutralPublicKeyTokenNull;
}

export interface BNPurchaseOrderApiResponsesUpdateCompletedDto {
    /** @format uuid */
    purchaseOrderId?: string;
}

export enum BNPurchaseOrderApiResponsesValidationErrorCode {
    IncompleteRequest = 'IncompleteRequest',
    IsEmpty = 'IsEmpty',
    InvalidFormat = 'InvalidFormat',
    InvalidValue = 'InvalidValue',
    NotBuyable = 'NotBuyable',
    DataFetchError = 'DataFetchError',
}

export interface BNPurchaseOrderApiResponsesValidationErrorDto {
    property?: string | null;
    errorMessage?: string | null;
    errorCode?: BNPurchaseOrderApiResponsesValidationErrorCode;
}

export interface BNPurchaseOrderApiResponsesValidationFailedDto {
    /** @format uuid */
    purchaseOrderId?: string;
    errors?: BNPurchaseOrderApiResponsesValidationErrorDto[] | null;
}

export interface BNPurchaseOrderApiResponsesValidationResponseDto {
    /** @format uuid */
    purchaseOrderId?: string;
    readyForCompletion?: boolean;
    errors?: BNPurchaseOrderApiResponsesValidationErrorDto[] | null;
}

export interface BNPurchaseOrderApiSimpleLeaseDto {
    /** @format double */
    downPayment?: number;

    /** @format double */
    monthlyPayment?: number;

    /** @format int32 */
    kmPerYear?: number;

    /** @format int32 */
    duration?: number;
    leasingType?: string | null;

    /** @format double */
    taxPerMonth?: number;

    /** @format double */
    taxBase?: number;

    /** @format double */
    totalPrice?: number;

    /** @format double */
    insurancePrMonth?: number;

    /** @format double */
    residualValue?: number;
    includeInsurance?: boolean;

    /** @format double */
    nominalInterest?: number;

    /** @format double */
    totalLeaseCosts?: number;
}

export interface BNPurchaseOrderApiSimpleLoanDto {
    /** @format int32 */
    paymentTerms?: number;

    /** @format double */
    paymentPerMonth?: number;

    /** @format double */
    nominalInterest?: number;

    /** @format double */
    aopBeforeTax?: number;

    /** @format double */
    totalLoanCosts?: number;

    /** @format double */
    downPayment?: number;

    /** @format double */
    downPaymentPct?: number;
    interestType?: string | null;
}

export interface BNSidePanelsApiAddressDto {
    address?: string | null;
    city?: string | null;
    zipCode?: string | null;
}

export enum BNSidePanelsApiBrandName {
    BjarneNielsen = 'BjarneNielsen',
    BilernesHus = 'BilernesHus',
    LeasingDK = 'LeasingDK',
    Motorpoint = 'Motorpoint',
}

export interface BNSidePanelsApiDealerShipDto {
    brandName?: BNSidePanelsApiBrandName;
    companyName?: string | null;
    phoneNumber?: string | null;
    address?: BNSidePanelsApiAddressDto;

    /** @format int32 */
    autoDesktopId?: number | null;
}

export enum BNSidePanelsApiMeetingType {
    Physical = 'Physical',
    ByPhone = 'ByPhone',
}

export interface BNSidePanelsApiNewCarInfoDto {
    make?: string | null;
    model?: string | null;
}

export interface BNSidePanelsApiPersonalInfoDto {
    firstName?: string | null;
    lastName?: string | null;
    companyName?: string | null;
    email?: string | null;
    phone?: string | null;

    /** @format uuid */
    consentRevisionAcceptedGuid?: string | null;
}

export interface BNSidePanelsApiRequestsBookMeetingRequestDto {
    personalInfo?: BNSidePanelsApiPersonalInfoDto;
    dealerShip?: BNSidePanelsApiDealerShipDto;

    /** @format date-time */
    dateTime?: string;
    meetingType?: BNSidePanelsApiMeetingType;
    comments?: string | null;
}

export interface BNSidePanelsApiRequestsBookTestDriveNewCarRequestDto {
    carInfo?: BNSidePanelsApiNewCarInfoDto;
    personalInfo?: BNSidePanelsApiPersonalInfoDto;
    dealerShip?: BNSidePanelsApiDealerShipDto;

    /** @format date-time */
    dateTime?: string;
    howToMeet?: string | null;
    comments?: string | null;
}

export interface BNSidePanelsApiRequestsBookTestDriveUsedCarRequestDto {
    carInfo?: BNSidePanelsApiUsedCarInfoDto;
    personalInfo?: BNSidePanelsApiPersonalInfoDto;
    dealerShip?: BNSidePanelsApiDealerShipDto;

    /** @format date-time */
    dateTime?: string;
    howToMeet?: string | null;
    comments?: string | null;
}

export interface BNSidePanelsApiRequestsCreateAskExpertRequestDto {
    name?: string | null;
    email?: string | null;
    phone?: string | null;
    questions?: string | null;
    url?: string | null;

    /** @format uuid */
    consentRevisionAcceptedGuid?: string | null;
}

export interface BNSidePanelsApiRequestsCreatePriceLeadRequestDto {
    name?: string | null;
    postalNumber?: string | null;
    email?: string | null;
    phone?: string | null;
    registrationNumber?: string | null;

    /** @format int32 */
    registrationYear?: number;

    /** @format int32 */
    registrationMonth?: number;
    brand?: string | null;
    model?: string | null;
    variant?: string | null;

    /** @format int32 */
    kilometers?: number;
    motor?: string | null;
    fuel?: string | null;

    /** @format int32 */
    overallCondition?: number;
    indicataUrl?: string | null;
    browserUrl?: string | null;

    /** @format double */
    indicataPrice?: number;

    /** @format uuid */
    consentRevisionAcceptedGuid?: string | null;
}

export interface BNSidePanelsApiRequestsGetCarOfferNewCarRequestDto {
    carInfo?: BNSidePanelsApiNewCarInfoDto;
    personalInfo?: BNSidePanelsApiPersonalInfoDto;
    dealerShip?: BNSidePanelsApiDealerShipDto;

    /** @format date-time */
    dateTime?: string;
    comments?: string | null;
}

export interface BNSidePanelsApiRequestsSubscribeNewsletterRequestDto {
    firstName?: string | null;
    lastName?: string | null;
    siteName?: string | null;
    email?: string | null;
    zipCode?: string | null;

    /** @format uuid */
    consentRevisionAcceptedGuid?: string | null;
}

export enum BNSidePanelsApiResponsesValidationErrorCode {
    IncompleteRequest = 'IncompleteRequest',
    IsEmpty = 'IsEmpty',
    InvalidFormat = 'InvalidFormat',
    InvalidValue = 'InvalidValue',
}

export interface BNSidePanelsApiResponsesValidationErrorDto {
    property?: string | null;
    errorCode?: BNSidePanelsApiResponsesValidationErrorCode;
}

export interface BNSidePanelsApiResponsesValidationFailedDto {
    errors?: BNSidePanelsApiResponsesValidationErrorDto[] | null;
}

export interface BNSidePanelsApiUsedCarInfoDto {
    /** @format int32 */
    carId?: number;
    vin?: string | null;

    /** @format double */
    price?: number;
    make?: string | null;
    model?: string | null;
    variant?: string | null;

    /** @format int32 */
    mileage?: number;

    /** @format int32 */
    registrationYear?: number;
}

export interface MicrosoftAspNetCoreMvcProblemDetails {
    type?: string | null;
    title?: string | null;

    /** @format int32 */
    status?: number | null;
    detail?: string | null;
    instance?: string | null;
}
