import { IAnchorNavigationItem } from './anchor-navigation.interface';
import { IImage } from './generic/image.interface';
import { ILink } from './generic/link.interface';
import { IPageBase } from './generic/page-base.interface';
import { IPageBreadcrumb } from './generic/page-breadcrumb.interface';
import { IPageContent } from './generic/page-content.interface';
import { IPageMeta } from './generic/page-meta.interface';
import { IDealership, IVehicleBrand, IVehicleModel } from './global-data-response.interface';
import { INavigationResponse } from './navigation-response.interface';
import { Tag } from './spots/shared/tag.model';
import { IUspItem } from './usp.item.interface';

export enum PAGE_TYPES {
    HOME_PAGE = 'home',
    ARTICLES_OVERVIEW_PAGE = 'articlesOverviewPage',
    ARTICLE_PAGE = 'articlePage',
    B2B_SECTION_PAGE = 'b2bSectionPage',
    BOOK_WORKSHOP_PAGE = 'bookWorkshopPage',
    CAMPAIGN_PAGE = 'campaignPage',
    CHECKOUT_PAGE = 'checkoutPage',
    LEASING_CHECKOUT_PAGE = 'leasingCheckoutPage',
    DEALERSHIP_PAGE = 'dealershipPage',
    DEALERSHIPS_OVERVIEW_PAGE = 'dealershipsOverviewPage',
    ERROR_PAGE = 'errorPage',
    GRID_LAYOUT_PAGE = 'gridLayoutPage',
    LEFT_NAVIGATION_GRID_LAYOUT_PAGE = 'leftNavigationGridLayoutPage',
    LINK_PAGE = 'linkPage',
    NAVIGATION_SECTION_PAGE = 'navigationSectionPage',
    NEW_CARS_OVERVIEW_PAGE = 'newCarsPage',
    ORDER_CONFIRMATION_PAGE = 'orderConfirmationPage',
    USED_CARS_DETAILS_PAGE = 'usedCarDetailsPage',
    USED_CARS_OVERVIEW_PAGE = 'usedCarsOverviewPage',
    VEHICLE_BRAND_PAGE = 'vehicleBrandPage',
    VEHICLE_MODEL_PAGE = 'vehicleModelPage',
    VEHICLE_MODEL_SUBPAGE = 'vehicleModelSubPage',
    VEHICLE_LEASING_OVERVIEW_PAGE = 'vehicleLeasingOverviewPage',
    VEHICLE_LEASING_BRAND_PAGE = 'vehicleLeasingBrandPage',
    VEHICLE_LEASING_MODEL_PAGE = 'vehicleLeasingModelPage',
    VEHICLE_VARIANT_PAGE = 'vehicleVariantPage',
    SIDEPANEL = 'sidepanel',
    SIDEPANEL_RICH_TEXT = 'sidepanelRichText',
    NOT_FOUND_PAGE = 'notFoundPage',
    FILTER_PAGE = 'filterPage',
}

interface IPageResponseBase extends IPageBase {
    readonly meta: IPageMeta;
    readonly content: IPageContent;
    readonly breadcrumbs: IPageBreadcrumb[];
    // readonly isB2BPage: boolean;
    isB2BPage: boolean;
    readonly transparentSiteHeader: boolean;
}

export interface IB2BPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.B2B_SECTION_PAGE;
}

export interface IErrorPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.ERROR_PAGE;
}

export interface INewCarsPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.NEW_CARS_OVERVIEW_PAGE;
    articleTagNavigation: Tag[];
}

export interface IArticlesOverviewPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.ARTICLES_OVERVIEW_PAGE;
    articleCategoriesTagNavigationHeader?: string;
    articleCategoriesTagNavigation?: Tag[];
    articleBrandsTagNavigationHeader?: string;
    articleBrandTagsNavigation?: Tag[];
    articlePages?: INavigationResponse[] | undefined;
}

export interface IArticlePageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.ARTICLE_PAGE;
    articleTitle: string;
    articleSubTitle: string;
    articleIntroImage: IImage;
    displayPageTopBanner: boolean;
    displaySmallTopBanner: boolean;
    topBannerOverlayOpacity: number;
    articleCategoryTags: Tag[];
    vehicleBrandTags: Tag[];
    articleCreatedDate: string;
    articleAuthor: any;
}

export interface ICampaignPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.CAMPAIGN_PAGE;
}

export interface INavigationSectionPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.NAVIGATION_SECTION_PAGE;
}

export interface ILeftNavigationGridLayoutPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.LEFT_NAVIGATION_GRID_LAYOUT_PAGE;
}

export interface ICheckoutPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.CHECKOUT_PAGE;
}

export interface IUsedCarDetailsPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.USED_CARS_DETAILS_PAGE;
    disclaimerSpecificationsText: string;
    galleryCtaLink: ILink;
    disclaimerFinancingText: string;
    usp: IUspItem[];
    financingHeader: string;
    usedCarId: string;
    notForSaleText: string;
    bookTestDriveCtaCategoryHeaderText?: string;
    bookTestDriveCtaHeaderText?: string;
    bookTestDriveCtaText?: string;
    leasingInfo?: string;
}

export interface IUsedCarOverviewPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.USED_CARS_OVERVIEW_PAGE;
    title: string;
    description: string;
    initialFilterFacetList?: string;
    prettyUrlParams?: string;
    productGridSpots: any[];
    productGridSpotsInterval: number;
    productGridSpotsStartingPosition: number;
    productGridSpotsRepeat: boolean;
    showLeasingPricesOnly: boolean;
}

export interface IVehicleBrandPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.VEHICLE_BRAND_PAGE;
    vehicleBrandData: IVehicleBrand;
    topBanner?: any;
    showTopBanner?: boolean;
    newCarsSpotData?: any;
    newCarsBrand: IVehicleBrand;
    newCarsLayoutMode?: string;
    newCarsModelExclusion?: IVehicleModel;
    displaySmallBanner?: boolean;
}

export interface IVehicleModelPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.VEHICLE_MODEL_PAGE;
    vehicleModelData: any;
    children?: INavigationResponse[] | undefined;
    parentUrl?: string;
    topBanner?: any;
    showTopBanner?: boolean;
    displaySmallBanner?: boolean;
    showTestDriveButton?: boolean;
}

export interface IVehicleModelSubPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.VEHICLE_MODEL_SUBPAGE;
    vehicleModelData: any;
    children?: INavigationResponse[] | undefined;
    parentUrl?: string;
    topBanner?: any;
    showTopBanner?: boolean;
    displaySmallBanner?: boolean;
}

export interface IVehicleLeasingOverviewPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.VEHICLE_LEASING_OVERVIEW_PAGE;
    leasingCarsNavigationHeader?: string;
}

export interface IVehicleLeasingBrandPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.VEHICLE_LEASING_BRAND_PAGE;
    vehicleBrandData: IVehicleBrand;
    topBanner?: any;
    showTopBanner?: boolean;
    newCarsSpotData?: any;
    newCarsBrand: IVehicleBrand;
    newCarsLayoutMode?: string;
    newCarsModelExclusion?: IVehicleModel;
    displaySmallBanner?: boolean;
}

export interface IVehicleLeasingModelPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.VEHICLE_LEASING_MODEL_PAGE;
    vehicleModelData: any;
    pageGridAnchorNavigationList: IAnchorNavigationItem[];
    children?: INavigationResponse[] | undefined;
    parentUrl?: string;
    topBanner?: any;
    showTopBanner?: boolean;
    displaySmallBanner?: boolean;
}

export interface IBookWorkshopPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.BOOK_WORKSHOP_PAGE;
    introduction: string;
    bookingSuccess: string;
    externalBookingOptional?: string;
    externalBookingOnly?: string;
    externalBookingButtonStayOnSite?: string;
    externalBookingButtonGoToExternalFlow?: string;
    rentalCarImages?: any[];
    deliveryOptionsInfoTexts: any[];
    datePickerRentalInformationText?: string;
    continueWithoutRentalText?: string;
    summaryCommentInfoText?: string;
}

export interface IDealershipsOverviewPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.DEALERSHIPS_OVERVIEW_PAGE;
    children?: INavigationResponse[];
    dealership: IDealership;
    employeesByDepartment?: any[];
    topBanner?: any;
    showTopBanner?: boolean;
    displaySmallBanner?: boolean;
    aboutDealershipText?: string;
    usedCarsHeaderText: string;
    usedCarsHeaderLink: ILink;
    usedCarsQuery: string;
    usedCarsLayoutMode: string;
    usedCarsDisplayAmount: number;
    usedCarsLastSlideText: string;
    usedCarsLastSlideLink: ILink;
    usedCarsSpotData: any;
}

export interface IDealershipPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.DEALERSHIP_PAGE;
    dealership: IDealership;
    employeesByDepartment?: any[];
    topBanner?: any;
    showTopBanner?: boolean;
    displaySmallBanner?: boolean;
    aboutDealershipText?: string;
    usedCarsHeaderText: string;
    usedCarsHeaderLink: ILink;
    usedCarsQuery: string;
    usedCarsLayoutMode: string;
    usedCarsDisplayAmount: number;
    usedCarsLastSlideText: string;
    usedCarsLastSlideLink: ILink;
    usedCarsSpotData: any;
}

export interface ILinkPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.ERROR_PAGE;
    pageLink?: ILink;
}

export interface ISidepanelPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.SIDEPANEL;
    primaryHeader?: string;
    secondaryHeader?: string;
    sidePanelContent?: any;
    sidePanelParameters?: string;
}

export interface ISidepanelRichTextPageResponse extends IPageResponseBase {
    readonly template: PAGE_TYPES.SIDEPANEL_RICH_TEXT;
    sidePanelHeader?: string;
    sidePanelRichText?: string;
}

export type PageResponse =
    | IB2BPageResponse
    | IBookWorkshopPageResponse
    | ICheckoutPageResponse
    | ILinkPageResponse
    | IArticlesOverviewPageResponse
    | IArticlePageResponse
    | ICampaignPageResponse
    | INavigationSectionPageResponse
    | INewCarsPageResponse
    | IUsedCarDetailsPageResponse
    | IUsedCarOverviewPageResponse
    | IVehicleBrandPageResponse
    | IVehicleModelPageResponse
    | IVehicleLeasingOverviewPageResponse
    | IVehicleLeasingBrandPageResponse
    | IVehicleLeasingModelPageResponse
    | IDealershipsOverviewPageResponse
    | IDealershipPageResponse
    | IPageResponseBase
    | ILeftNavigationGridLayoutPageResponse
    | ISidepanelPageResponse;
